import React, { useEffect, useRef, useState } from "react";
import { BaselinkerInventory, BaselinkerOrder, BaselinkerSentReportType, BaselinkerStatus, BaselinkerType, BaselinkerWarehouse, CombinedBaselinkerProductOrderId, GetOrdersProduct, GetSentReportResponseBodyData, GetSentReportResponseBodyDataContent, GetSentReportResponseBodyDataCurrencyContent } from "../../../types";
import styles from './styles.module.css';
import { Option } from "../../../components";
import Loading from "../../../components/Loading/Loading";
import { getBaselinkerSentReport, getBaselinkerReportBaseData, getBaselinkerOrders } from "../../../api/backAdminApi";
import { useAppDispatch } from "../../../store/hooks";
import { addMessageWithTimeOut } from "../../../store/features/comunicatorSlice";
import Btn from "../../../components/Btns/Btn";
import CheckBox from "../../../components/CheckBoxs/CheckBox";
import { NavLink, Navigate, Outlet, Route, Routes } from "react-router-dom";
import AccountantReport from "./Report/Accountent/AccountantReport";
import WarehouseReport from "./Report/Warehouse/WarehouseReport";

const BaselinkerSentReport = () => {
    const dispatch = useAppDispatch();
    const curDate = new Date().toLocaleDateString();

    const sentTypes: BaselinkerSentReportType[] = ["sent", "basic return", "client return"];
    const dbs: BaselinkerType[] = ["global"];
    const subInventories = ['Local', 'Isklad'] as const;
    type SubInventory = typeof subInventories[number];

    const [selectedSubInventory, setSelectedSubInventory] = useState<SubInventory>('Local');
    const [baselinker, setBaselinker] = useState<BaselinkerType>(dbs[0]);
    const [sentType, setSentType] = useState<BaselinkerSentReportType>(sentTypes[0]);
    const [srcSelectedStatus, setSrcSelectedStatus] = useState<BaselinkerStatus | null>(null);
    const [destNormalSelectedStatus, setDestNormalSelectedStatus] = useState<BaselinkerStatus | null>(null);
    const [destCashSelectedStatus, setDestCashSelectedStatus] = useState<BaselinkerStatus | null>(null);
    const [destOnlineSelectedStatus, setDestOnlineSelectedStatus] = useState<BaselinkerStatus | null>(null);
    const [selectedInventory, setselectedInventory] = useState<BaselinkerInventory | null>(null);
    const [updateStatuses, setUpdateStatuses] = useState<boolean>(false);

    const [returnedToSenderOrders, setReturnedToSenderOrders] = useState<BaselinkerOrder[]>([]);
    const [clientReturnIncludeProducts, setClientReturnIncludeProducts] = useState<CombinedBaselinkerProductOrderId[]>([]);

    const [statuses, setStatuses] = useState<BaselinkerStatus[]>([]);
    const [inventories, setInventories] = useState<BaselinkerInventory[]>([]);

    const [report, setReport] = useState<GetSentReportResponseBodyData | null>(null);

    const generateSimpleSelect = (values: string[], label: string, setValue: Function, defaultValue?: any) => {
        return (
            <div className={styles['select-container']}>
                <label>{label}</label>
                <select value={defaultValue ? defaultValue : values[0]} onChange={(e) => setValue(e.target.value)}>
                    ${values.map((value, index) => (<Option key={index} value={value} content={value.toUpperCase()} />))}
                </select>
            </div>
        )
    }

    const generateStatusSelect = (statuses: BaselinkerStatus[], setStatus: Function, label: string, defaultValue?: BaselinkerStatus | null) => {
        defaultValue = defaultValue ? defaultValue : statuses[0];

        return (
            <div className={styles['select-container']}>
                <label>{label}</label>
                <select value={defaultValue.id.toString()} onChange={(e) => setStatus(statuses.find(status => status.id.toString() === e.target.value))}>
                    ${statuses.map((status) => (<Option key={status.id} value={status.id.toString()} content={status.name} />))}
                </select>
            </div>
        )
    }

    const generateInventorySelect = (inventories: BaselinkerInventory[], setStatus: Function, label: string, defaultValue?: BaselinkerInventory | null) => {
        return (
            <div className={styles['select-container']}>
                <label>{label}</label>
                <select value={defaultValue ? defaultValue.inventory_id.toString() : inventories[0]?.inventory_id.toString()} onChange={(e) => setStatus(inventories.find(status => status.inventory_id.toString() === e.target.value))}>
                    ${inventories.map((inventory) => (<Option key={inventory.inventory_id} value={inventory.inventory_id.toString()} content={inventory.name} />))}
                </select>
            </div>
        )
    }

    const setTypeHelper = (newType: BaselinkerSentReportType) => {
        setSentType(newType);
        setClientReturnIncludeProducts([]);
        setReturnedToSenderOrders([]);
        setReport(null);
        setSrcSelectedStatusHelper(statuses, newType, selectedInventory, selectedSubInventory);
        setDestNormalSelectedStatusHelper(statuses, newType, selectedInventory, selectedSubInventory);
        setDestOnlineSelectedStatusHelper(statuses, newType, selectedInventory, selectedSubInventory);
        setDestCashSelectedStatusHelper(statuses, newType, selectedInventory, selectedSubInventory);
    }

    const setselectedInventoryHelper = (newInventory: BaselinkerInventory) => {
        setselectedInventory(newInventory);
        setSrcSelectedStatusHelper(statuses, sentType, newInventory, selectedSubInventory);
        setDestNormalSelectedStatusHelper(statuses, sentType, newInventory, selectedSubInventory);
        setDestOnlineSelectedStatusHelper(statuses, sentType, newInventory, selectedSubInventory);
        setDestCashSelectedStatusHelper(statuses, sentType, newInventory, selectedSubInventory);
    }

    const setSelectedSubInventoryHelper = (newSubInventory: SubInventory) => {
        setSelectedSubInventory(newSubInventory);
        setSrcSelectedStatusHelper(statuses, sentType, selectedInventory, newSubInventory);
        setDestNormalSelectedStatusHelper(statuses, sentType, selectedInventory, newSubInventory);
        setDestOnlineSelectedStatusHelper(statuses, sentType, selectedInventory, newSubInventory);
        setDestCashSelectedStatusHelper(statuses, sentType, selectedInventory, newSubInventory);
    }

    const setSrcSelectedStatusHelper = (statuses: BaselinkerStatus[], sentType: BaselinkerSentReportType, inventory: BaselinkerInventory | null, subInventory: SubInventory | null) => {
        const status = statuses.find((status) => {
            if (inventory?.name.toLowerCase() === 'easyshop') {
                switch (sentType) {
                    case 'sent':
                        if (status.name.toLowerCase() === 'sent report es') {
                            return status;
                        }
                        break;
                    case 'basic return':
                        if (status.name.toLowerCase() === 'kyryl zwrot es') return status;
                        break;
                    case 'client return':
                        if (status.name.toLowerCase() === 'returned goods report es') return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'robeauty' && subInventory === 'Local') {
                switch (sentType) {
                    case 'sent':
                        if (status.name.toLowerCase() === 'sent report rb') return status;
                        break;
                    case 'basic return':
                        if (status.name.toLowerCase() === 'kyryl zwrot rb') return status;
                        break;
                    case 'client return':
                        if (status.name.toLowerCase() === 'returned goods report rb') return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'robeauty' && subInventory === 'Isklad') {
                switch (sentType) {
                    case 'sent':
                        if (status.name.toLowerCase() === 'sent report rb isklad') return status;
                        break;
                    case 'basic return':
                        if (status.name.toLowerCase() === 'Cancelled D. Report ISKLAD RB'.toLowerCase()) return status;
                        break;
                    case 'client return':
                        return statuses[0];
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'teddybear') {
                switch (sentType) {
                    case 'sent':
                        if (status.name.toLowerCase() === 'sent report tb') {
                            return status;
                        }
                        break;
                    case 'basic return':
                        if (status.name.toLowerCase() === 'kyryl zwrot tb') return status;
                        break;
                    case 'client return':
                        if (status.name.toLowerCase() === 'returned goods report tb') return status;
                        break;
                    default:
                        break;
                }
            }
        });
        if (status) setSrcSelectedStatus(status);
        else setSrcSelectedStatus(statuses[0]);
    }

    const setDestNormalSelectedStatusHelper = (statuses: BaselinkerStatus[], sentType: BaselinkerSentReportType, inventory: BaselinkerInventory | null, subInventory: SubInventory| null) => {
        const status = statuses.find((status) => {
            if (inventory?.name.toLowerCase() === 'easyshop') {
                switch (sentType) {
                    case 'sent':
                        if (status.name.toLowerCase() === 'sent es') return status;
                        break;
                    case 'client return':
                        if (status.name.toLowerCase() === 'returned goods es') return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'robeauty' && subInventory === 'Local') {
                switch (sentType) {
                    case 'sent':
                        if (status.name.toLowerCase() === 'sent rb') return status;
                        break;
                    case 'client return':
                        if (status.name.toLowerCase() === 'returned goods rb') return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'robeauty' && subInventory === 'Isklad') {
                switch (sentType) {
                    case 'sent':
                        if (status.name.toLowerCase() === 'transferred to isklad rb') return status;
                        break;
                    case 'basic return':
                        if (status.name.toLowerCase() === 'Cancelled Delivery ISKLAD RB'.toLowerCase()) return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'teddybear') {
                switch (sentType) {
                    case 'sent':
                        if (status.name.toLowerCase() === 'sent tb') return status;
                        break;
                    case 'client return':
                        if (status.name.toLowerCase() === 'returned goods tb') return status;
                        break;
                    default:
                        break;
                }
            } 
        });
        if (status) setDestNormalSelectedStatus(status);
        else setDestNormalSelectedStatus(statuses[0]);
    }

    const setDestOnlineSelectedStatusHelper = (statuses: BaselinkerStatus[], sentType: BaselinkerSentReportType, inventory: BaselinkerInventory | null, subInventory: SubInventory| null) => {
        const status = statuses.find((status) => {
            if (inventory?.name.toLowerCase() === 'easyshop') {
                switch (sentType) {
                    case 'basic return':
                        if (status.name.toLowerCase() === 'cancelled del. online es') return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'robeauty' && subInventory === 'Local') {
                switch (sentType) {
                    case 'basic return':
                        if (status.name.toLowerCase() === 'cancelled del. online rb') return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'robeauty' && subInventory === 'Isklad') {
                switch (sentType) {
                    case 'basic return':
                        if (status.name.toLowerCase() === 'Cancelled Delivery ISKLAD RB'.toLowerCase()) return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'teddybear') {
                switch (sentType) {
                    case 'basic return':
                        if (status.name.toLowerCase() === 'cancelled del. online tb') return status;
                        break;
                    default:
                        break;
                }
            }
        });
        if (status) setDestOnlineSelectedStatus(status);
        else setDestOnlineSelectedStatus(statuses[0]);
    }

    const setDestCashSelectedStatusHelper = (statuses: BaselinkerStatus[], sentType: BaselinkerSentReportType, inventory: BaselinkerInventory | null, subInventory: SubInventory| null) => {
        const status = statuses.find((status) => {
            if (inventory?.name.toLowerCase() === 'easyshop') {
                switch (sentType) {
                    case 'basic return':
                        if (status.name.toLowerCase() === 'returned to sender es') return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'robeauty' && subInventory === 'Local') {
                switch (sentType) {
                    case 'basic return':
                        if (status.name.toLowerCase() === 'returned to sender rb' && subInventory === 'Local') return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'robeauty' && subInventory === 'Isklad') {
                switch (sentType) {
                    case 'basic return':
                        if (status.name.toLowerCase() === 'Cancelled Delivery ISKLAD RB'.toLowerCase()) return status;
                        break;
                    default:
                        break;
                }
            } else if (inventory?.name.toLowerCase() === 'teddybear') {
                switch (sentType) {
                    case 'basic return':
                        if (status.name.toLowerCase() === 'returned to sender tb') return status;
                        break;
                    default:
                        break;
                }
            }
        });
        if (status) setDestCashSelectedStatus(status);
        else setDestCashSelectedStatus(statuses[0]);
    }


    const setSelectedInventoryHelper = (inventories: BaselinkerInventory) => {
        setselectedInventory(inventories);
    }


    const fetchStatuses = async (baselinker: BaselinkerType) => {
        try {
            setStatuses([]);
            setInventories([]);
            setSrcSelectedStatus(null);
            setDestNormalSelectedStatus(null);
            setDestOnlineSelectedStatus(null);
            setDestCashSelectedStatus(null);
            setClientReturnIncludeProducts([]);
            setReport(null);
            setReturnedToSenderOrders([]);
            const responseData = await getBaselinkerReportBaseData({ baselinker: baselinker });
            if (responseData.error) {
                dispatch(addMessageWithTimeOut(responseData.message, true));
            } else {
                const fetchSelectedInventory = responseData.data.inventories[0];
                setStatuses(responseData.data.statuses);
                setInventories(responseData.data.inventories);
                setSelectedInventoryHelper(fetchSelectedInventory)
                setSrcSelectedStatusHelper(responseData.data.statuses, sentType, fetchSelectedInventory, selectedSubInventory);
                setDestNormalSelectedStatusHelper(responseData.data.statuses, sentType, fetchSelectedInventory, selectedSubInventory);
                setDestOnlineSelectedStatusHelper(responseData.data.statuses, sentType, fetchSelectedInventory, selectedSubInventory);
                setDestCashSelectedStatusHelper(responseData.data.statuses, sentType, fetchSelectedInventory, selectedSubInventory);
            }
        } catch (e: any) {
            dispatch(addMessageWithTimeOut('Can not fetch statuses data', true));
        }
    }

    const onSubmitButton = async () => {
        if (checkIfAllDataSet()) {
            dispatch(addMessageWithTimeOut('Loading...', false));
            const response = await getBaselinkerSentReport({
                baselinker,
                selectedSrcStatus: srcSelectedStatus as BaselinkerStatus,
                selectedDestNormalStatus: destNormalSelectedStatus as BaselinkerStatus,
                selectedDestCashStatus: destCashSelectedStatus as BaselinkerStatus,
                selectedDestOnlineStatus: destOnlineSelectedStatus as BaselinkerStatus,
                sentType,
                selectedInventory: selectedInventory as BaselinkerInventory,
                updateStatuses,
                products: sentType === 'client return' ? clientReturnIncludeProducts : undefined
            });

            if (response.error) {
                dispatch(addMessageWithTimeOut(response.message, true));
            } else {

                // const blob = new Blob(['asdasdasdas'], {type: 'text/plain'});
                // const url = URL.createObjectURL(blob);
                // const download = document.createElement('a');
                // download.download = `Report_${sentType.replace(/ /g, '_')}_${new Date().toLocaleDateString()}.csv`
                // download.href = url;

                // download.click();
                setReport(response.data);
                dispatch(addMessageWithTimeOut('Done :)', false));
            }
        } else {
            dispatch(addMessageWithTimeOut(`Please wait for data`, true));
        }
    }

    const checkIfAllDataSet = () => {
        if (baselinker && srcSelectedStatus && sentType && selectedInventory) return true;
        return false;
    }

    const getSumProducts = (order: BaselinkerOrder) => {
        return order.products.reduce( (prev, cur) => prev + cur.price_brutto, 0);
    }

    const onSelectAll = (order: BaselinkerOrder) => {
        const paied = getSumProducts(order) >= order.payment_done;
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            let newClientSentProducts = JSON.parse(JSON.stringify(clientReturnIncludeProducts)) as CombinedBaselinkerProductOrderId[];
            if(e.target.checked) {
                order.products.forEach( product => {
                    newClientSentProducts.push({
                        order: {orderId: order.order_id, currency: order.currency, paied, countryCode: order.delivery_country_code},
                        product
                    })
                })

                //delivery
                newClientSentProducts.push({
                    order: {orderId: order.order_id, currency: order.currency, paied, countryCode: order.delivery_country_code},
                    product: {
                        sku: `${order.order_id}`,
                        name: `Delivery${order.order_id}`,
                        price_brutto: order.delivery_price,
                        product_id: getDeliveryProductId(order),
                        variant_id: getDeliveryProductId(order)
                    }
                })
            } else {
                newClientSentProducts = newClientSentProducts.filter( clientProduct => clientProduct.order.orderId !== order.order_id);
            }

            setClientReturnIncludeProducts(newClientSentProducts);
        }
    }

    const checkIfSelectAllShoulBeChecked = (order: BaselinkerOrder, clientReturnIncludeProducts: CombinedBaselinkerProductOrderId[]): boolean => {
        return clientReturnIncludeProducts.map( product => product.order.orderId).includes(order.order_id);
    }

    const generateOrders = (orders: BaselinkerOrder[]) => {
        return (
            <div className={styles["client-return-orders"]}>
                {orders.map((order, index) => (
                    <div key={order.order_id} className={`${styles["client-return-order"]} ${index % 2 === 0 && styles['client-return-orders-color']}`}>
                        <div className={`${styles["client-return-order-side"]}`}>
                            <input className={styles["client-return-order-checkbox"]} checked={checkIfSelectAllShoulBeChecked(order, clientReturnIncludeProducts)} type="checkbox" onChange={onSelectAll(order)}/>
                            <div className={styles["client-return-order-id"]}>{order.order_id}</div>
                        </div>
                        {generateProducts(order.products, order)}
                    </div>
                ))}
            </div>
        )
    }

    const addProductToSelectedProducts = (product: Partial<GetOrdersProduct>, order: BaselinkerOrder) => {
        const paied = getSumProducts(order) >= order.payment_done;
        return (e: React.ChangeEvent<HTMLInputElement>): void => {
            if(e.target.checked) {
                setClientReturnIncludeProducts([...clientReturnIncludeProducts, 
                {
                    order: {
                        orderId: order.order_id, currency: order.currency, paied, countryCode: order.delivery_country_code
                    },
                    product: JSON.parse(JSON.stringify(product))
                }]);
            } else {
                setClientReturnIncludeProducts(clientReturnIncludeProducts.filter( selectedProduct => {
                    if(selectedProduct.order.orderId === order.order_id && selectedProduct.product.order_product_id === product.order_product_id) {
                        return false;
                    } else return true;
                }))
            }
        }
    }

    const isProductSelected = (selectedProducts: CombinedBaselinkerProductOrderId[], product: Partial<GetOrdersProduct>, order: BaselinkerOrder): boolean => {
        return selectedProducts.find( selectedProduct => {
            return selectedProduct.order.orderId === order.order_id && selectedProduct.product.order_product_id === product.order_product_id
        }) ? true : false;
    }

    const getSelectedProduct = (selectedProducts: CombinedBaselinkerProductOrderId[], product: Partial<GetOrdersProduct>, order: BaselinkerOrder): CombinedBaselinkerProductOrderId | undefined => {
        return selectedProducts.find( selectedProduct => {
            return selectedProduct.order.orderId === order.order_id && selectedProduct.product.order_product_id === product.order_product_id
        })
    }

    const getProductQuantity = (downloadedProduct: Partial<GetOrdersProduct>, selectedProducts: CombinedBaselinkerProductOrderId[], order: BaselinkerOrder) => {
        const selectedProduct = getSelectedProduct(selectedProducts, downloadedProduct, order);
        
        if(selectedProduct) {
            return selectedProduct.product.quantity;
        } else {
            return downloadedProduct.quantity;
        }
    }

    const changeProductNumber = (downloadedProduct: GetOrdersProduct, selectedProducts: CombinedBaselinkerProductOrderId[], order: BaselinkerOrder) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const newSelectedProducts = JSON.parse(JSON.stringify(selectedProducts));
            const selectedProduct = getSelectedProduct(newSelectedProducts, downloadedProduct, order);

            if(Number.parseInt(e.target.value) <= downloadedProduct.quantity && selectedProduct) {
                selectedProduct.product.quantity = Number.parseInt(e.target.value);
                setClientReturnIncludeProducts(newSelectedProducts);
            }
        }
    }

    const returnProduct = (downloadedProduct: GetOrdersProduct, selectedProducts: CombinedBaselinkerProductOrderId[], order: BaselinkerOrder) => {
        const selectedProduct = getSelectedProduct(selectedProducts, downloadedProduct, order);

        if(selectedProduct) return selectedProduct.product;
        else return downloadedProduct;
    }

    const getDeliveryProductId = (order: BaselinkerOrder): string => {
        if(order && order.products) {
            for(let product of order.products) {
                if(product.storage_id === 11201) return '1135448036';
                else if(product.storage_id === 11100) return '1134400285';
            }
        }

        throw new Error(`Can not find products with one of specified inventories. Please ask admin what to do.`)
    }

    const onCheckDelivery = (order: BaselinkerOrder) => {
        const paied = getSumProducts(order) >= order.payment_done;
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            let newClientSelectedProducts = JSON.parse(JSON.stringify(clientReturnIncludeProducts)) as CombinedBaselinkerProductOrderId[];
            if(e.target.checked) {
                newClientSelectedProducts.push({
                    order: {orderId: order.order_id, currency: order.currency, paied, countryCode: order.delivery_country_code},
                    product: {
                        name: `Delivery{${order.order_id}}`,
                        price_brutto: order.delivery_price,
                        sku: `${order.order_id}`,
                        product_id: getDeliveryProductId(order),
                        variant_id: getDeliveryProductId(order)
                    }
                })
            } else {
                newClientSelectedProducts = newClientSelectedProducts.filter( clientProduct => clientProduct.order.orderId !== order.order_id);
            }

            setClientReturnIncludeProducts(newClientSelectedProducts);
        }
    }

    const generateProducts = (products: GetOrdersProduct[], order: BaselinkerOrder) => {
        return (
            <div className={styles["client-return-products"]}>
                <div className={`${styles["client-return-product"]} ${styles['client-return-product-filters']}`}>
                    <span className={styles["client-return-product-checkbox-filter"]}></span>
                    <span className={styles["client-return-product-name-filter"]}>NAME</span>
                    <span className={styles["client-return-product-id-filter"]}>ID</span>
                    <span className={styles["client-return-product-sku-filter"]}>SKU</span>
                    <span className={styles["client-return-product-quantity-filter"]}>QUANTITY</span>
                </div>
                <div className={styles["client-return-products-items"]}>
                    {products.map(product => {
                        const downloadedProduct = product;
                        const newProduct = returnProduct(product, clientReturnIncludeProducts, order);

                        return (
                            <div key={newProduct.order_product_id} className={styles["client-return-product"]}>
                                <input className={styles["client-return-product-checkbox"]} type="checkbox" checked={isProductSelected(clientReturnIncludeProducts, newProduct, order)} onChange={addProductToSelectedProducts(newProduct, order)} />
                                <div className={styles["client-return-product-name"]}>{newProduct.name}</div>
                                <div className={styles["client-return-product-id"]}>{newProduct.product_id}</div>
                                <div className={styles["client-return-product-sku"]}>{newProduct.sku}</div>
                                <div className={styles["client-return-product-quantity-container"]}>
                                    <input onChange={changeProductNumber(downloadedProduct, clientReturnIncludeProducts, order)} type="number" className={styles["client-return-product-quantity"]} value={getProductQuantity(newProduct, clientReturnIncludeProducts, order)} />
                                    <span> /</span>
                                    <span> {downloadedProduct.quantity}</span>
                                </div>
                            </div>
                        )
                    })}
                    <div className={styles["client-return-product"]}>
                        <input className={styles["client-return-product-checkbox"]} type="checkbox" checked={clientReturnIncludeProducts.find( clientProduct => clientProduct.product.sku === `${order.order_id}`) ? true : false} onChange={onCheckDelivery(order)} />
                        <div className={styles["client-return-product-name"]}>Delivery({order.delivery_price})</div>
                        <div className={styles["client-return-product-id"]}>{getDeliveryProductId(order)}</div>
                        <div className={styles["client-return-product-sku"]}></div>
                        <div className={styles["client-return-product-quantity-static"]}>{1}</div>
                    </div>
                </div>
            </div>
        )
    }

    const onGetOrdersData = async (status: BaselinkerStatus) => {
        setClientReturnIncludeProducts([]);
        setReport(null);

        const response = await getBaselinkerOrders({
            baselinker,
            status: status
        });

        setReturnedToSenderOrders(response.data);
    }

    const generateSubInventorySelect = (selectedInventory: BaselinkerInventory | null, setSubInventory: Function, label: string) => {    
        return (
            <>
                {selectedInventory?.inventory_id === 11100 && <div className={styles['select-container']}>
                    <label>{label}</label>
                    <select value={selectedSubInventory} onChange={(e) => setSubInventory(subInventories.find(subInventory => subInventory === e.target.value))}>
                        ${subInventories.map((subInventory, id) => (<Option key={id} value={subInventory} content={subInventory} />))}
                    </select>
                </div>}
            </>
        )
    }

    useEffect(() => {
        fetchStatuses(baselinker);
        console.log('Test');
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles['selectors-container']}>
                {generateSimpleSelect(dbs, 'Select baselinker', setBaselinker, baselinker)}
                {statuses.length > 0 && generateSimpleSelect(sentTypes, 'Type', setTypeHelper, sentType)}
                {inventories.length > 0
                    ? generateInventorySelect(inventories, setselectedInventoryHelper, 'Baselinker catalog', selectedInventory)
                    : <Loading />}
                {generateSubInventorySelect(selectedInventory, setSelectedSubInventoryHelper, 'Store')}
                {statuses.length > 0
                    ? generateStatusSelect(statuses, setSrcSelectedStatus, 'Src status', srcSelectedStatus)
                    : <Loading />}
                {statuses.length > 0
                    ? (sentType === 'sent' || sentType === 'client return') && updateStatuses && generateStatusSelect(statuses, setDestNormalSelectedStatus, 'Destination status', destNormalSelectedStatus)
                    : (updateStatuses && (sentType === 'sent' || sentType === 'client return')) && <Loading />}
                {statuses.length > 0
                    ? (sentType === 'basic return') && updateStatuses && generateStatusSelect(statuses, setDestCashSelectedStatus, 'Cash destination status', destCashSelectedStatus)
                    : (updateStatuses && (sentType === 'basic return')) && <Loading />}
                {statuses.length > 0
                    ? (sentType === 'basic return') && updateStatuses && generateStatusSelect(statuses, setDestOnlineSelectedStatus, 'Online destination status', destOnlineSelectedStatus)
                    : (updateStatuses && (sentType === 'basic return')) && <Loading />}
            </div>
            <div className={styles['fetch-controllers']}>
                {(sentType === 'client return' && srcSelectedStatus)
                    && <div>
                        {<Btn value={`Get "${srcSelectedStatus.name}" orders data`} onClick={() => onGetOrdersData(srcSelectedStatus)} />}
                    </div>    
                }
                {((sentType === 'sent' || sentType === 'basic return') || (sentType === 'client return' && clientReturnIncludeProducts.length > 0))
                    && <div className={styles['get-report-container']}>
                        {<Btn value="Get Report" onClick={onSubmitButton} />}
                        {<CheckBox value={updateStatuses} setValue={setUpdateStatuses} labelName="Update statuses?" />}
                    </div>
                }
            </div>
            {(sentType === 'client return' && !report)
                && <div>
                    <h2 className={styles["client-return-title"]}>Specify returned products</h2>
                    {generateOrders(returnedToSenderOrders)}
                </div>
            }
            {report && <>
                <div className={styles['report-container']}>
                    <div className={styles['report-nav']}>
                        {report.accounting && <NavLink className={({isActive}) => (`${styles['report-nav-item']} ${isActive ? styles['report-nav-item-active'] : ''}`)} to='accountent'>Accounting report</NavLink>}
                        {report.accounting && <NavLink className={({isActive}) => (`${styles['report-nav-item']} ${isActive ? styles['report-nav-item-active'] : ''}`)} to='warehouse'>Warehouse report</NavLink>}
                    </div>
                </div>
                <Routes>
                    <Route path="/" element={<Navigate to={'accountent'}/>} />
                    <Route path="accountent" element={<AccountantReport report={report.accounting} sentType={sentType} curDate={curDate} />}/>
                    <Route path="warehouse" element={<WarehouseReport report={report.warehouse} sentType={sentType} curDate={curDate} />}/>
                </Routes>
            </>}
        </div>
    )
}

export default BaselinkerSentReport;